
import { Browser } from "@capacitor/browser";
import {
  IonIcon,
  toastController,
  IonSlides,
  IonSlide,
  IonSpinner,
  IonRefresher,
  IonRefresherContent,
  IonImg,
  IonButtons,
  IonButton,
} from "@ionic/vue";
import { defineComponent, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import Empty from "../../../components/Empty.vue";
import { useStore } from "vuex";
import { Analytics } from "../../../common/analytics";
import { useI18n } from "vue-i18n";

/**
 * services
 */
import services from "../../../services";

export default defineComponent({
  components: {
    IonIcon,
    IonSlides,
    IonSlide,
    IonSpinner,
    IonRefresher,
    IonRefresherContent,
    Empty,
    IonImg,
    IonButtons,
    IonButton,
  },
  props: {
    // postId
    tab: {
      type: [String],
      default: () => null,
    },
    lat: {
      type: [String],
      default: () => null,
    },
    lng: {
      type: [String],
      default: () => null,
    },
  },
  emits: ["onTab"],
  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const store = useStore();
    const screenName = "PAGE-MISSION_HOME";
    const analytics = new Analytics();
    // Optional parameters to pass to the swiper instance. See http://idangero.us/swiper/api/ for valid options.
    const state = reactive({
      groupDistricts: {
        loading: true,
        items: [],
        totalItems: 0,
        totalPages: 0,
      },
      groupCategory: {
        loading: true,
        items: [],
        totalItems: 0,
        totalPages: 0,
      },
      advertisement: {
        loading: true,
        items: [],
        totalItems: 0,
        totalPages: 0,
      },
      banner: {
        loading: true,
        items: [],
        totalItems: 0,
        totalPages: 0,
      },
      event: {
        loading: true,
        items: [],
        totalItems: 0,
        totalPages: 0,
      },
      contentLoading: true,
    });

    const slideOpts = {
      slidesPerView: "auto",
      speed: 400,
      pagination: false,
    };
    const slideOpts02 = {
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      speed: 400,
    };

    const openToast = async (message: any) => {
      const toast = await toastController.create({
        message: message,
        duration: 2000,
      });
      return toast.present();
    };

    const fetch = async (event: any = null) => {
      await services
        .groupDistrictsFineAll({
          lat: props.lat,
          lng: props.lng,
        })
        .then(
          (response) => {
            const { data } = response;

            if (event) {
              if (event.type == "ion-refresh") {
                state.groupDistricts.items = [];
              }
            }

            state.groupDistricts.items = data.items;
            state.groupDistricts.totalItems = data.totalItems;
            state.groupDistricts.totalPages = data.totalPages;
            state.groupDistricts.loading = false;
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            if (event) {
              if (event.type == "ion-refresh") {
                state.groupDistricts.items = [];
              }
            }
            state.groupDistricts.loading = false;
          }
        );
      await services
        .groupCategoryFindAll({
          lat: props.lat,
          lng: props.lng,
        })
        .then(
          (response) => {
            const { data } = response;

            if (event) {
              if (event.type == "ion-refresh") {
                state.groupCategory.items = [];
              }
            }

            state.groupCategory.items = data.items;
            state.groupCategory.totalItems = data.totalItems;
            state.groupCategory.totalPages = data.totalPages;
            state.groupCategory.loading = false;
            console.log(state.groupCategory);
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            if (event) {
              if (event.type == "ion-refresh") {
                state.groupCategory.items = [];
              }
            }
            state.groupCategory.loading = false;
          }
        );
      await services
        .advertisementFeedFindAll({
          type: "mission.home",
        })
        .then(
          (response) => {
            const { data } = response;

            if (event) {
              if (event.type == "ion-refresh") {
                state.advertisement.items = [];
              }
            }

            state.advertisement.items = data.items;
            state.advertisement.totalItems = data.totalItems;
            state.advertisement.totalPages = data.totalPages;
            state.advertisement.loading = false;
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            if (event) {
              if (event.type == "ion-refresh") {
                state.advertisement.items = [];
              }
            }
            state.advertisement.loading = false;
          }
        );
      await services
        .bannerGeneralFineAll({
          screen: "mission.home",
        })
        .then(
          (response) => {
            const { data } = response;
            if (event) {
              if (event.type == "ion-refresh") {
                state.banner.items = [];
              }
            }

            data.items.some((item: any) => {
              item.title = item.title.replace(/\n/g, "<br />");
              item.shortText = item.shortText.replace(/\n/g, "<br />");
            });

            state.banner.items = data.items;
            state.banner.totalItems = data.totalItems;
            state.banner.totalPages = data.totalPages;
            state.banner.loading = false;
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            if (event) {
              if (event.type == "ion-refresh") {
                state.banner.items = [];
              }
            }
            state.banner.loading = false;
          }
        );

      await services
        .eventFindAll({
          screen: "mission.home",
        })
        .then(
          (response) => {
            const { data } = response;
            if (event) {
              if (event.type == "ion-refresh") {
                state.event.items = [];
              }
            }

            data.items.some((item: any) => {
              item.title = item.title.replace(/\n/g, "<br />");
              item.shortText = item.shortText.replace(/\n/g, "<br />");
            });

            state.event.items = data.items;
            state.event.totalItems = data.totalItems;
            state.event.totalPages = data.totalPages;
            state.event.loading = false;
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            if (event) {
              if (event.type == "ion-refresh") {
                state.event.items = [];
              }
            }
            state.event.loading = false;
          }
        );

      setTimeout(() => {
        state.contentLoading = false;
        if (event) {
          event.target.complete();
        }
      }, 500);
    };

    const onGroupLink = (id: number, title: string) => {
      store.commit("page", {
        title: title,
      });
      router.push({
        name: "GroupId",
        params: { id: id },
      });
    };

    const validURL = (str: string) => {
      const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    };

    const onEventLink = async (item: any) => {
      if (validURL(item.url)) {
        await Browser.open({ url: item.url });
      }
      // else {
      //   openToast('링크를 열수 없습니다.');
      // }
    };

    const onOpenUrl = async (url: string) => {
      if (validURL(url)) {
        await Browser.open({ url: url });
      }
      // else {
      //   openToast('링크를 열수 없습니다.');
      // }
    };

    // 새로고침
    const onRefresh = (event: any) => {
      state.groupDistricts.loading = true;
      state.groupCategory.loading = true;
      state.advertisement.loading = true;
      state.banner.loading = true;
      state.event.loading = true;

      fetch(event);
    };

    onMounted(() => {
      state.groupDistricts.items = [];
      state.groupCategory.items = [];
      state.advertisement.items = [];
      state.banner.items = [];
      state.event.items = [];

      fetch();
      console.log("onMounted");
      analytics.setScreenName(screenName);
    });

    return {
      t,
      slideOpts,
      slideOpts02,
      state,
      onGroupLink,
      onRefresh,
      onEventLink,
      onOpenUrl,
    };
  },
});
