
import { IonPage, IonContent, IonSpinner } from "@ionic/vue";
import { defineComponent, onMounted, reactive } from "vue";
import HomeMission from "../../views/Mission/Home/Mission.vue";
import RewardsMission from "../../views/Mission/Rewards/Rewards.vue";
import RegionMission from "../../views/Mission/Region/Region.vue";
import BadgeMission from "../../views/Mission/Badge/Badge.vue";
import { Geolocation } from "@capacitor/geolocation";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonSpinner,
    HomeMission,
    RewardsMission,
    BadgeMission,
    RegionMission,
  },
  setup() {
    const store = useStore();
    const state = reactive({
      tab: "Home",
      geolocation: {
        lat: 0,
        lng: 0,
      },
      contentLoading: true,
    });

    const slideOpts = {
      slidesPerView: "auto",
      speed: 400,
      pagination: false,
    };

    const onTab = (tab: any) => {
      state.tab = tab;
    };

    const onLocation = async () => {
      const getGeoLocation = store.getters["getGeoLocation"];
      console.log(getGeoLocation)
      if (getGeoLocation.lat && getGeoLocation.lng) {
        state.geolocation.lat = getGeoLocation.lat;
        state.geolocation.lng = getGeoLocation.lng;
      }
      if (state.geolocation.lat == 0 && state.geolocation.lng == 0) {
        Geolocation.getCurrentPosition({
          enableHighAccuracy: true,
          timeout: 500,
        })
          .then((response) => {
            console.log(response);
            const latitude = response.coords.latitude;
            const longitude = response.coords.longitude;

            store.commit("geolocation", {
              lat: latitude,
              lng: longitude,
            });

            state.geolocation.lat = latitude;
            state.geolocation.lng = longitude;
            console.log("helloGeolocation 3");
            state.contentLoading = false;
          })
          .catch((error) => {
            console.log(error.message);
            state.contentLoading = false;
          });
      } else {
        state.contentLoading = false;
      }
    };

    onMounted(() => {
      console.log("onMounted");
      onLocation();
    });

    return { state, slideOpts, onTab };
  },
});
