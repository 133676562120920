
import { Browser } from "@capacitor/browser";
import {
  toastController,
  IonSlides,
  IonSlide,
  IonSpinner,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
  IonImg,
} from "@ionic/vue";
import { defineComponent, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import Empty from "../../../components/Empty.vue";
import { Analytics } from "../../../common/analytics";
import services from "../../../services";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    IonSlides,
    IonSlide,
    IonSpinner,
    Empty,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
    IonImg,
  },
  props: {
    // postId
    tab: {
      type: [String],
      default: () => null,
    },
    lat: {
      type: [String],
      default: () => null,
    },
    lng: {
      type: [String],
      default: () => null,
    },
  },
  emits: ["onTab"],
  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const screenName = "PAGE-MISSION_BADGE";
    const analytics = new Analytics();
    const state = reactive({
      mission: {
        loading: true,
        items: [{}],
        totalItems: 0,
        totalPages: 0,
        size: 20,
        page: 1,
      },
      event: {
        loading: true,
        items: [],
        totalItems: 0,
        totalPages: 0,
      },
      contentLoading: true,
      isScrollDisabled: false,
    });
    const slideOpts = {
      slidesPerView: "auto",
      speed: 400,
      pagination: false,
    };
    const slideOpts02 = {
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      speed: 400,
    };

    const openToast = async (message: any) => {
      const toast = await toastController.create({
        message: message,
        duration: 2000,
      });
      return toast.present();
    };

    const fetch = async (event: any = null, loadMore = false) => {
      const params = {
        size: state.mission.size,
        page: state.mission.page,
        lat: props.lat,
        lng: props.lng,
      };
      await services.missionBadgeFindAll(params).then(
        (response) => {
          const { data } = response;

          if (event) {
            if (event.type == "ion-refresh") {
              state.mission.items = [];
            }
          }

          data.items.some((item: any) => {
            if (item.complete == 0) {
              if (item.content.reward[0].pictureGray) {
                if (item.content.reward[0].pictureGray.name) {
                  item.content.reward[0].picture =
                    item.content.reward[0].pictureGray;
                }
              }
            }
            if (item.completeStampCount < item.acquisitionStampCount) {
              item.acquisitionStampCount = item.completeStampCount;
            }
            state.mission.items.push(item);
          });
          state.mission.totalItems = data.totalItems;
          state.mission.totalPages = data.totalPages;
          state.mission.loading = false;
          state.mission.page++;
          if (event) {
            if (data.items.length == 0 && loadMore) {
              state.isScrollDisabled = true;
            }
          }
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          console.log(message);
          if (event) {
            if (event.type == "ion-refresh") {
              state.mission.items = [];
            }
          }
          state.isScrollDisabled = true;
          state.mission.loading = false;
        }
      );

      if (!loadMore) {
        await services
          .bannerGeneralFineAll({
            screen: "mission.badge",
          })
          .then(
            (response) => {
              const { data } = response;

              if (event) {
                if (event.type == "ion-refresh") {
                  state.event.items = [];
                }
              }

              data.items.some((item: any) => {
                item.title = item.title.replace(/\n/g, "<br />");
                item.shortText = item.shortText.replace(/\n/g, "<br />");
              });

              state.event.items = data.items;
              state.event.totalItems = data.totalItems;
              state.event.totalPages = data.totalPages;
              state.event.loading = false;
            },
            (error) => {
              let message = "";
              try {
                message = error.response.data.message;
              } catch (e) {
                message = "";
                console.log(e);
              }
              console.log(message);
              if (event) {
                if (event.type == "ion-refresh") {
                  state.mission.items = [];
                }
              }
              state.event.loading = false;
            }
          );
      }

      setTimeout(() => {
        if (event) {
          event.target.complete();
        }

        state.contentLoading = false;
      }, 500);
    };

    const validURL = (str: string) => {
      const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    };

    const onEventLink = async (item: any) => {
      if (validURL(item.url)) {
        await Browser.open({ url: item.url });
      }
      // else {
      //   openToast('링크를 열수 없습니다.');
      // }
    };

    // 새로고침
    const onRefresh = (event: any) => {
      state.mission.page = 1;
      state.mission.loading = true;
      state.event.loading = true;

      fetch(event);
    };

    onMounted(() => {
      state.mission.items = [];
      state.event.items = [];

      fetch();
      console.log("onMounted");
      analytics.setScreenName(screenName);
    });

    const onLoadData = (event: any) => {
      setTimeout(() => {
        fetch(event, true);
      }, 500);
    };

    const onMissionLink = (id: number) => {
      router.push({
        name: "MissionId",
        params: { id: id },
      });
    };

    return {
      t,
      slideOpts,
      slideOpts02,
      state,
      onMissionLink,
      onLoadData,
      onRefresh,
      onEventLink,
    };
  },
});
