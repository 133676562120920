
import { Browser } from "@capacitor/browser";
import {
  toastController,
  IonSpinner,
  IonRefresher,
  IonRefresherContent,
  IonImg,
  IonSlides,
  IonSlide,
} from "@ionic/vue";
import { defineComponent, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import Empty from "../../../components/Empty.vue";
import { Analytics } from "../../../common/analytics";
import { useI18n } from "vue-i18n";

/**
 * services
 */
import services from "../../../services";

export default defineComponent({
  components: {
    IonSpinner,
    IonRefresher,
    IonRefresherContent,
    Empty,
    IonImg,
    IonSlides,
    IonSlide,
  },
  props: {
    // postId
    tab: {
      type: [String],
      default: () => null,
    },
    lat: {
      type: [String],
      default: () => null,
    },
    lng: {
      type: [String],
      default: () => null,
    },
  },
  emits: ["onTab"],
  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const screenName = "PAGE-MISSION_REWARD";
    const analytics = new Analytics();
    const state = reactive({
      mission: {
        loading: true,
        items: [{}],
        allItems: [],
        totalItems: 0,
        totalPages: 0,
      },
      festival: {
        loading: true,
        items: [{}],
        allItems: [],
        totalItems: 0,
        totalPages: 0,
      },
      event: {
        loading: true,
        items: [],
        totalItems: 0,
        totalPages: 0,
      },
      advertisement: {
        loading: true,
        items: [],
        totalItems: 0,
        totalPages: 0,
      },
      contentLoading: true,
    });
    const slideOpts = {
      slidesPerView: "auto",
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      speed: 400,
    };

    const openToast = async (message: any) => {
      const toast = await toastController.create({
        message: message,
        duration: 2000,
      });
      return toast.present();
    };

    const onMissionLoad = (limit = 5) => {
      const items = state.mission.allItems;
      state.mission.items = [];
      if (items) {
        items.some((item) => {
          if (state.mission.items.length < limit) {
            state.mission.items.push(item);
          } else {
            return false;
          }
        });
      }
    };
    const onFestivalLoad = (limit = 5) => {
      const items = state.festival.allItems;
      state.festival.items = [];
      if (items) {
        items.some((item) => {
          if (state.festival.items.length < limit) {
            state.festival.items.push(item);
          } else {
            return false;
          }
        });
      }
    };

    const fetch = async (event: any = null) => {
      console.log(state.mission.items);
      await services
        .missionRewardFindAll({
          lat: props.lat,
          lng: props.lng,
        })
        .then(
          (response) => {
            const { data } = response;

            if (event) {
              if (event.type == "ion-refresh") {
                state.mission.items = [];
              }
            }

            data.items?.some((item: any) => {
              if (item.completeStampCount < item.acquisitionStampCount) {
                item.acquisitionStampCount = item.completeStampCount;
              }
            });

            state.mission.allItems = data.items;
            state.mission.totalItems = data.totalItems;
            state.mission.totalPages = data.totalPages;
            state.mission.loading = false;
            console.log(state.mission);

            onMissionLoad(5);
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            if (event) {
              if (event.type == "ion-refresh") {
                state.mission.items = [];
              }
            }
            state.mission.loading = false;
          }
        );

      await services
        .festivalFineAll({
          lat: props.lat,
          lng: props.lng,
        })
        .then(
          (response) => {
            const { data } = response;
            data.items.forEach((item: any) => {
              if (new Date().getTime() < new Date(item.startDate).getTime()) {
                item.status = t("planning");
              } else if (
                new Date(item.endDate).getTime() < new Date().getTime()
              ) {
                item.status = t("end");
              } else {
                item.status = t("ongoing");
              }
            });
            if (event) {
              if (event.type == "ion-refresh") {
                state.festival.items = [];
              }
            }

            state.festival.allItems = data.items;
            state.festival.totalItems = data.totalItems;
            state.festival.totalPages = data.totalPages;
            state.festival.loading = false;
            console.log(state.festival);

            onFestivalLoad(5);
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            if (event) {
              if (event.type == "ion-refresh") {
                state.festival.items = [];
              }
            }
            state.festival.loading = false;
          }
        );
      await services
        .bannerGeneralFineAll({
          screen: "mission.reward",
        })
        .then(
          (response) => {
            const { data } = response;

            if (event) {
              if (event.type == "ion-refresh") {
                state.event.items = [];
              }
            }

            data.items?.some((item: any) => {
              item.title = item.title.replace(/\n/g, "<br />");
              item.shortText = item.shortText.replace(/\n/g, "<br />");
            });

            state.event.items = data.items;
            state.event.totalItems = data.totalItems;
            state.event.totalPages = data.totalPages;
            state.event.loading = false;
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            if (event) {
              if (event.type == "ion-refresh") {
                state.event.items = [];
              }
            }
            state.event.loading = false;
          }
        );

      await services
        .advertisementFeedFindAll({
          type: "mission.reward",
        })
        .then(
          (response) => {
            const { data } = response;

            if (event) {
              if (event.type == "ion-refresh") {
                state.advertisement.items = [];
              }
            }

            state.advertisement.items = data.items;
            state.advertisement.totalItems = data.totalItems;
            state.advertisement.totalPages = data.totalPages;
            state.advertisement.loading = false;
          },
          (error) => {
            let message = "";
            try {
              message = error.response.data.message;
            } catch (e) {
              message = "";
              console.log(e);
            }
            console.log(message);
            if (event) {
              if (event.type == "ion-refresh") {
                state.advertisement.items = [];
              }
            }
            state.advertisement.loading = false;
          }
        );

      setTimeout(() => {
        state.contentLoading = false;
        if (event) {
          event.target.complete();
        }
      }, 500);
    };

    const validURL = (str: string) => {
      const pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    };

    const onEventLink = async (item: any) => {
      if (validURL(item.url)) {
        await Browser.open({ url: item.url });
      }
      // else {
      //   openToast('링크를 열수 없습니다.');
      // }
      /*
      router.push({
        name: "EventId",
        params: {id: id}
      })
      */
    };

    // 새로고침
    const onRefresh = (event: any) => {
      state.mission.loading = true;
      state.festival.loading = true;
      state.event.loading = true;
      state.advertisement.loading = true;

      fetch(event);
    };

    const onOpenUrl = async (url: string) => {
      if (validURL(url)) {
        await Browser.open({ url: url });
      }
      // else {
      //   openToast('링크를 열수 없습니다.');
      // }
    };

    // onMounted
    onMounted(() => {
      state.mission.items = [];
      state.festival.items = [];
      state.event.items = [];
      state.advertisement.items = [];

      fetch();
      console.log("onMounted");
      analytics.setScreenName(screenName);
    });

    const onMissionLink = (id: number) => {
      router.push({
        name: "MissionId",
        params: { id: id },
      });
    };

    const onFestivalLink = (id: number) => {
      router.push({
        name: "FestivalId",
        params: { id: id },
      });
    };

    return {
      t,
      slideOpts,
      state,
      onMissionLink,
      onMissionLoad,
      onFestivalLoad,
      onFestivalLink,
      onRefresh,
      onEventLink,
      onOpenUrl,
    };
  },
});
