
import {
  IonSpinner,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRefresher,
  IonRefresherContent,
} from "@ionic/vue";
import { defineComponent, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import Empty from "../../../components/Empty.vue";
import { Analytics } from "../../../common/analytics";
import { useI18n } from "vue-i18n";

/**
 * services
 */
import services from "../../../services";

export default defineComponent({
  components: {
    IonSpinner,
    Empty,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonRefresher,
    IonRefresherContent,
  },
  props: {
    // postId
    tab: {
      type: [String],
      default: () => null,
    },
    lat: {
      type: [String],
      default: () => null,
    },
    lng: {
      type: [String],
      default: () => null,
    },
  },
  emits: ["onTab"],
  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const screenName = "PAGE-MISSION_REGION";
    const analytics = new Analytics();
    const state = reactive({
      mission: {
        loading: true,
        items: [{}],
        totalItems: 0,
        totalPages: 0,
        size: 20,
        page: 1,
      },
      region: {
        loading: true,
        items: [],
        totalItems: 0,
        totalPages: 0,
        areaName: "",
      },

      contentLoading: true,
      isScrollDisabled: false,
    });

    const fetch = async (event: any = null, loadMore = false) => {
      if (!loadMore) {
        await services
          .regionFindall({
            lat: props.lat,
            lng: props.lng,
          })
          .then(
            (response) => {
              const { data } = response;

              if (event) {
                if (event.type == "ion-refresh") {
                  state.region.items = [];
                }
              }

              state.region.items = data.items;
              state.region.totalItems = data.totalItems;
              state.region.totalPages = data.totalPages;
              state.region.loading = false;
            },
            (error) => {
              let message = "";
              try {
                message = error.response.data.message;
              } catch (e) {
                message = "";
                console.log(e);
              }
              console.log(message);
              if (event) {
                if (event.type == "ion-refresh") {
                  state.mission.items = [];
                }
              }
              state.mission.loading = false;
              state.region.loading = false;
            }
          );
      }

      console.log(state.region.areaName);

      const params = {
        region: state.region.areaName,
        page: state.mission.page,
        size: state.mission.size,
        lat: props.lat,
        lng: props.lng,
      };

      await services.missionRegionFindAll(params).then(
        (response) => {
          const { data } = response;

          if (event) {
            if (event.type == "ion-refresh") {
              state.mission.items = [];
            }
          }

          data.items.some((item: any) => {
            if (item.completeStampCount < item.acquisitionStampCount) {
              item.acquisitionStampCount = item.completeStampCount;
            }

            if (item.distance) {
              const distance = item.distance / 1000;
              console.log(distance);
              item.distancekm = distance;
            }

            state.mission.items.push(item);
          });

          state.mission.totalItems = data.totalItems;
          state.mission.totalPages = data.totalPages;
          state.mission.loading = false;
          state.mission.page++;
          if (event) {
            if (data.items.length == 0 && loadMore) {
              state.isScrollDisabled = true;
            }
          }
        },
        (error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          console.log(message);
          if (event) {
            if (event.type == "ion-refresh") {
              state.mission.items = [];
            }
          }
          state.isScrollDisabled = true;
          state.mission.loading = false;
        }
      );

      setTimeout(() => {
        if (event) {
          event.target.complete();
        }

        state.contentLoading = false;
      }, 500);
    };

    // 새로고침
    const onRefresh = (event: any) => {
      state.mission.page = 1;
      state.mission.loading = true;
      state.region.loading = true;

      fetch(event);
    };

    // onMounted
    onMounted(() => {
      state.region.items = [];
      state.mission.items = [];

      fetch();
      console.log("onMounted");
      analytics.setScreenName(screenName);
    });

    const onMissionLink = (id: number) => {
      router.push({
        name: "MissionId",
        params: { id: id },
      });
    };

    const onRegion = (region: string) => {
      if (state.region.areaName == region) {
        state.region.areaName = "";
      } else {
        state.region.areaName = region;
      }

      state.mission.loading = true;
      state.mission.items = [];
      state.mission.page = 1;
      state.mission.size = 20;

      fetch(null, false);
    };

    const onLoadData = (event: any) => {
      setTimeout(() => {
        fetch(event, true);
      }, 500);
    };

    return { t, state, onMissionLink, onLoadData, onRegion, onRefresh };
  },
});
